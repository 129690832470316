import React, { useState } from "react";

import { useState as useHookState } from "@hookstate/core";
import { useForm, SubmitHandler } from "react-hook-form";
import { AxiosError } from "axios";

import { navigate } from "gatsby";
import md5 from "md5";

import UserIcon from "../../images/user@2x.png";
import ParkGenieImg from "../../images/login-image.png";
import ParkGenieLogo from "../../images/logo_partner@2x.png";
import QrCode from "../../images/qr-code-user@2x.png";

import Spinner from "../spinner";
import Loader from "../Loader";

import Server from "../../utils/Server";
import { Login, RoleLogin } from "../../utils/Auth";

import AppState from "../../States/AppState";

import { getUserQuery } from "../../Query/User";

import { User } from "../../Interfaces/User";

import * as LoginStyle from "./Login.module.scss";

import { popoverAlert } from "../../utils/Alert";
import Logger from "../../utils/Logger";

import AreaRoles from "../../States/AreaRoles";
import client from "../../gatsby-plugin-apollo/client";

interface Inputs {
  email: string;
  password: string;
}

const Index = () => {
  const { register, handleSubmit } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = ({ email, password }) => {
    if (email && password) {
      setIsLoading(true);
      Server.post("/login/email?isPartner=true", {
        email: email,
        password: md5(password),
      })
        .then((res: any) => {
          Login(res, false).then((userId) => {
            client
              .query<{ user: User }>({
                query: getUserQuery,
                variables: { id: userId },
                fetchPolicy: "network-only",
              })
              .then((response) => {
                navigate(RoleLogin(response.data.user));
                const managersAreas =
                  response.data.user.parkingAreaManagers?.collection.map(
                    ({ role, parkingArea: { id } }) => {
                      return {
                        areaId: id,
                        role: role,
                      };
                    }
                  ) || [];
                const userAreas =
                  response.data?.user?.parkingAreas?.collection.map(
                    ({ id }) => ({
                      areaId: id,
                      role: ["ROLE_PARTNER"],
                    })
                  ) || [];

                AreaRoles.areas.set([...managersAreas, ...userAreas]);
                AppState.set({
                  isUserAuthenticated: true,
                  isStarting: false,
                  user: response.data.user,
                });
              })
              .catch((error) => {
                AppState.isStarting.set(false);
                popoverAlert({ msg: error.message, icon: "error" });
              });
          });
        })
        .catch((err: AxiosError) => {
          Logger.showError(err);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      <div className={LoginStyle.container} onSubmit={handleSubmit(onSubmit)}>
        <div
          {...{ style: { backgroundImage: `url(${ParkGenieImg})` } }}
          className={LoginStyle.pargenieAd}
        >
          <div>
            <img src={ParkGenieLogo} alt="logo" width={150} />
          </div>
          <div className={LoginStyle.pargenieAd__qrcode}>
            <span>
              When you want to book parking.you will have to use the ParkGenie
              User App.The ParkGenie User App can be downloaded by using the
              below QR Code
            </span>
            <img src={QrCode} alt="qrcode" width={150} />
          </div>
        </div>
        <form className={LoginStyle.form}>
          <div className="boldText color-primary heading1">Login</div>
          <div className={LoginStyle.form__group}>
            <input
              placeholder="Enter Email Or Contact Number"
              className="input"
              {...register("email", { required: true })}
            />
          </div>
          <div className={LoginStyle.form__group}>
            <input
              placeholder="Enter Password"
              className="input"
              type="password"
              {...register("password", { required: true })}
            />
          </div>

          <div className={`${LoginStyle.form__group} justify-center`}>
            <div className={LoginStyle.form__group_signup}>
              <Spinner area="login">
                <button
                  className="btn btn__primary"
                  type="submit"
                  value="Login"
                >
                  Login
                </button>
              </Spinner>
            </div>
          </div>

          <div className={`${LoginStyle.orText} color-primary textMd`}>Or</div>
          <div className={`${LoginStyle.form__group} justify-center`}>
            <div className={LoginStyle.form__group_swk}>
              <button
                onClick={() => navigate("/signup")}
                className="btn grediant1"
                value="Sign up"
                type="button"
              >
                <img src={UserIcon} alt="login" width={24} height={24} />
                <span>New User? Sign Up</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
};

export default Index;

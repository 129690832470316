import React from "react";

import Layout from "../Components/Layout";
import Login from "../Components/Login";

const LoginPage = () => {
  return (
    <Layout nonLogingPage showHeader>
      <Login />
    </Layout>
  );
};

export default LoginPage;
